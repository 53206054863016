@import 'var';
@import 'resetAntd';
// @import 'icon';
// @import 'svg';
// @import 'button';

.g-appRoot {
  overflow-x: hidden;
  .aek-flex {
    display: flex;
  }
  .aek-flex-1 {
    flex: 2;
  }
  .aek-flex-2 {
    flex: 2;
  }
  .aek-flex-3 {
    flex: 3;
  }
  .aek-flex-4 {
    flex: 4;
  }
  .aek-flex-5 {
    flex: 5;
  }
  .aek-flex-6 {
    flex: 6;
  }

  .aek-vertical-middle {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .aek-vertical-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .aek-vertical-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .aek-vertical-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .aek-stretch-middle {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }

  .aek-stretch-left {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
  }

  .aek-stretch-right {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
  }

  .aek-stretch-between {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }

  .aek-align-start {
    display: flex;
    align-items: flex-start;
  }

  .aek-column-around {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .aek-column-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .aek-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .aek-flex-column {
    display: flex;
    flex-direction: column;
  }

  .aek-flex-center {
    display: flex;
    justify-content: center;
  }

  .aek-flex-between {
    display: flex;
    justify-content: space-between;
  }

  .aek-flex-wrap {
    flex-wrap: wrap;
  }

  .aek-img {
    width: 100%;
    height: 100%;
  }

  .aek-center {
    align-content: center;
  }

  .aek-inline-block {
    display: inline-block;
  }
  .aek-align-left {
    text-align: left;
  }
  .aek-align-cente {
    text-align: center;
  }
  .aek-align-right {
    text-align: right;
  }
  .aek-align-justify {
    text-align: justify;
  }

  .aek-text-between {
    text-align-last: justify;
    text-align: justify;
    text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
  }

  /** 标签手势和hover */
  .aek-link,
  .aek-link * {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $aek-gray;
    cursor: pointer;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    &:hover {
      color: $aek-primary;
      .st6 {
        fill: $aek-primary;
      }
    }
  }

  .aek-transition-hover,
  .aek-transition-hover * {
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: linear;
  }

  .aek-disabled {
    cursor: no-drop;
    color: #e0e0e0;
  }

  .aek-block {
    display: block;
  }

  .aek-border-top-dashed {
    border-top: 1px dashed #999999;
  }

  .aek-cursor-point {
    cursor: pointer;
  }

  .aek-border-top-none {
    border-top: none;
  }

  .aek-border-left-none {
    border-left: none;
  }

  .aek-border-right-none {
    border-right: none;
  }

  .aek-border-bottom-none {
    border-bottom: none;
  }

  .aek-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .aek-pos-relative {
    position: relative;
  }

  .aek-lh-25 {
    line-height: 0.25rem;
  }

  .aek-lh-70 {
    line-height: 0.7rem;
  }

  /** 默认size的按钮 */
  .aek-btn-primary,
  .aek-btn-default,
  .aek-btn-dange,
  .aek-btn-transparence {
    padding: 0 0.15rem;
    min-width: 0.6rem;
    height: 0.3rem;
    line-height: 0.29rem;
    font-size: 12px;
    color: #000;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  /** small size的按钮 */
  .aek-btn-primary-small,
  .aek-btn-default-small,
  .aek-btn-dange-small {
    padding: 0 0.1rem;
    height: 0.24rem;
    line-height: 0.23rem;
    font-size: 12px;
    border-radius: 2px;
  }

  .aek-btn-default,
  .aek-btn-default-small {
    color: #000;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    &:hover {
      color: #45cef1;
      border-color: #45cef1;
    }
  }
  .aek-btn-primary,
  .aek-btn-primary-small {
    color: #fff;
    background-color: $aek-primary;
    border: 1px solid $aek-primary;
    &:hover {
      background: #45cef1;
      border-color: #45cef1;
    }
  }
  .aek-btn-dange,
  .aek-btn-dange-small {
    color: #fff;
    background-color: #ff6e6e;
    border: 1px solid #ff6e6e;
  }
  .aek-btn-transparence,
  .aek-btn-transparence-small {
    color: #fff;
    background-color: #ff6e6e00;
    border: 1px solid #ffffff;
    &:hover {
      color: #000;
      background: #ffffff;
      border-color: #ffffff;
    }
  }

  /** 遮挡层 */
  .aek-popup {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .aek-transform-0 {
    transform: scale(0);
    transition: transform 0.3s ease-in;
  }
  .aek-transform-1 {
    transform: scale(1);
  }

  /*
    命名规则 aek-(m: margin, p: padding)(方向 tb: 上下, lr: 左右, t: 上, 下: b, 左: l, 右: r)(大小)
  */
  .aek-ptm20 {
    padding: 0.2rem 0;
  }
  .aek-ptb10 {
    padding: 0.1rem 0;
  }
  .aek-ptb24 {
    padding-top: 0.12rem;
    padding-bottom: 0.12rem;
  }
  .aek-ptb15 {
    padding: 0.15rem 0;
  }
  .aek-ptb20 {
    padding: 0.2rem 0;
  }
  .aek-ptb24 {
    padding-top: 0.24rem;
    padding-bottom: 0.24rem;
  }
  .aek-ptb30 {
    padding: 0.3rem 0;
  }
  .aek-plr10 {
    padding: 0 0.1rem;
  }
  .aek-plr15 {
    padding: 0 0.15rem;
  }
  .aek-plr20 {
    padding: 0 0.2rem;
  }
  .aek-plr25 {
    padding: 0 0.25rem;
  }
  .aek-plr30 {
    padding: 0 0.3rem;
  }
  /* 内边距 */
  .aek-pr-none {
    padding-right: 0;
  }
  .aek-p20 {
    padding: 0.2rem;
  }

  .aek-pt0 {
    padding-top: 0;
  }

  .aek-pt1 {
    padding-top: 1px;
  }

  .aek-pt5 {
    padding-top: 0.05rem;
  }

  .aek-pt10 {
    padding-top: 0.1rem;
  }

  .aek-pt15 {
    padding-top: 0.15rem;
  }

  .aek-pt20 {
    padding-top: 0.2rem;
  }

  .aek-pt25 {
    padding-top: 0.25rem;
  }

  .aek-pt30 {
    padding-top: 0.3rem;
  }

  .aek-pt35 {
    padding-top: 0.35rem;
  }

  .aek-pt40 {
    padding-top: 0.4rem;
  }

  .aek-pt45 {
    padding-top: 0.45rem;
  }

  .aek-pt70 {
    padding-top: 0.7rem;
  }
  .aek-pt100 {
    padding-top: 1rem;
  }
  .aek-pr5 {
    padding-right: 0.05rem;
  }

  .aek-pr8 {
    padding-right: 0.08rem;
  }

  .aek-pr10 {
    padding-right: 0.1rem;
  }

  .aek-pr15 {
    padding-right: 0.15rem;
  }

  .aek-pr20 {
    padding-right: 0.2rem;
  }

  .aek-pr22 {
    padding-right: 0.22rem;
  }

  .aek-pr25 {
    padding-right: 0.25rem;
  }

  .aek-pr30 {
    padding-right: 0.3rem;
  }

  .aek-pr35 {
    padding-right: 0.35rem;
  }

  .aek-pr40 {
    padding-right: 0.4rem;
  }

  .aek-pr45 {
    padding-right: 0.45rem;
  }

  .aek-pl5 {
    padding-left: 0.05rem;
  }

  .aek-pl10 {
    padding-left: 0.1rem;
  }

  .aek-pr10 {
    padding-right: 0.1rem;
  }

  .aek-pl12 {
    padding-left: 0.12rem;
  }

  .aek-pl15 {
    padding-left: 0.15rem;
  }

  .aek-pl20 {
    padding-left: 0.2rem;
  }

  .aek-pl25 {
    padding-left: 0.25rem;
  }

  .aek-pl30 {
    padding-left: 0.3rem;
  }

  .aek-pl35 {
    padding-left: 0.35rem;
  }

  .aek-pl40 {
    padding-left: 0.4rem;
  }

  .aek-pl45 {
    padding-left: 0.45rem;
  }

  .aek-pb1 {
    padding-bottom: 1px;
  }

  .aek-pb5 {
    padding-bottom: 0.05rem;
  }

  .aek-pb10 {
    padding-bottom: 0.1rem;
  }

  .aek-pb15 {
    padding-bottom: 0.15rem;
  }

  .aek-pb20 {
    padding-bottom: 0.2rem;
  }

  .aek-pb25 {
    padding-bottom: 0.25rem;
  }

  .aek-pb30 {
    padding-bottom: 0.3rem;
  }

  .aek-pb32 {
    padding-bottom: 0.32rem;
  }

  .aek-pb35 {
    padding-bottom: 0.35rem;
  }

  .aek-pb40 {
    padding-bottom: 0.4rem;
  }

  .aek-pb45 {
    padding-bottom: 0.45rem;
  }

  /* */
  .aek-ptb20-lr10 {
    padding: 0.2rem 0.1rem;
  }

  .aek-pt20-lr10 {
    padding: 0.2rem 0.1rem 0 0.1rem;
  }

  .aek-plr10 {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }

  .aek-plr20 {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }

  .aek-plr25 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .aek-ptb10 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }

  .aek-ptb15 {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem;
  }

  .aek-ptb20 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  .aek-ptb30 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }

  .aek-p40 {
    padding: 0.4rem;
  }

  /* 外边距 */
  .aek-mt5 {
    margin-top: 0.05rem;
  }

  .aek-mt10 {
    margin-top: 0.1rem;
  }

  .aek-mt15 {
    margin-top: 0.15rem;
  }

  .aek-mt20 {
    margin-top: 0.2rem;
  }

  .aek-mt25 {
    margin-top: 0.25rem;
  }

  .aek-mt30 {
    margin-top: 0.3rem;
  }

  .aek-mt35 {
    margin-top: 0.35rem;
  }

  .aek-mt40 {
    margin-top: 0.4rem;
  }

  .aek-mt45 {
    margin-top: 0.45rem;
  }

  .aek-mt50 {
    margin-top: 0.5rem;
  }

  .aek-mt70 {
    margin-top: 0.7rem;
  }

  .aek-mr3 {
    margin-right: 0.03rem;
  }
  .aek-mr5 {
    margin-right: 0.05rem;
  }
  .aek-mr10 {
    margin-right: 0.1rem;
  }
  .aek-mr12 {
    margin-right: 0.12rem;
  }
  .aek-mr15 {
    margin-right: 0.15rem;
  }
  .aek-mr20 {
    margin-right: 0.2rem;
  }
  .aek-mr22 {
    margin-right: 0.22rem;
  }
  .aek-mr25 {
    margin-right: 0.25rem;
  }
  .aek-mr30 {
    margin-right: 0.3rem;
  }
  .aek-mr70 {
    margin-right: 0.7rem;
  }

  .aek-ml3 {
    margin-left: 0.03rem;
  }
  .aek-ml5 {
    margin-left: 0.05rem;
  }
  .aek-ml10 {
    margin-left: 0.1rem;
  }
  .aek-ml12 {
    margin-left: 0.12rem;
  }
  .aek-ml15 {
    margin-left: 0.15rem;
  }
  .aek-ml20 {
    margin-left: 0.2rem;
  }
  .aek-ml25 {
    margin-left: 0.25rem;
  }
  .aek-ml30 {
    margin-left: 0.3rem;
  }
  .aek-ml35 {
    margin-left: 0.35rem;
  }
  .aek-ml40 {
    margin-left: 0.4rem;
  }

  .aek-mb0 {
    margin-bottom: 0;
  }
  .aek-mb3 {
    margin-bottom: 0.03rem;
  }
  .aek-mb5 {
    margin-bottom: 0.05rem;
  }
  .aek-mb10 {
    margin-bottom: 0.1rem;
  }
  .aek-mb15 {
    margin-bottom: 0.15rem;
  }
  .aek-mb17 {
    margin-bottom: 0.17rem;
  }
  .aek-mb18 {
    margin-bottom: 0.18rem;
  }
  .aek-mb20 {
    margin-bottom: 0.2rem;
  }
  .aek-mb25 {
    margin-bottom: 0.25rem;
  }
  .aek-mb27 {
    margin-bottom: 0.27rem;
  }
  .aek-mb30 {
    margin-bottom: 0.3rem;
  }
  .aek-mb32 {
    margin-bottom: 0.32rem;
  }
  .aek-mb35 {
    margin-bottom: 0.35rem;
  }
  .aek-mb40 {
    margin-bottom: 0.4rem;
  }
  .aek-mb45 {
    margin-bottom: 0.45rem;
  }
  .aek-mb50 {
    margin-bottom: 0.5rem;
  }
  .aek-mb60 {
    margin-bottom: 0.6rem;
  }

  .aek-mtb5 {
    margin-top: 0.05rem;
    margin-bottom: 0.05rem;
  }
  .aek-mtb10 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }
  .aek-mtb15 {
    margin-top: 0.15rem;
    margin-bottom: 0.15rem;
  }
  .aek-mtb18 {
    margin-top: 0.18rem;
    margin-bottom: 0.18rem;
  }
  .aek-mtb20 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
  .aek-mtb30 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }
  .aek-mtb30 {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  .aek-mlr3 {
    margin-left: 0.03rem;
    margin-right: 0.03rem;
  }
  .aek-mlr5 {
    margin-left: 0.05rem;
    margin-right: 0.05rem;
  }
  .aek-mlr10 {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
  .aek-mlr15 {
    margin-left: 0.15rem;
    margin-right: 0.15rem;
  }
  .aek-mlr20 {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
  .aek-mlr30 {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
  .aek-mlr40 {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }

  .aek-m5 {
    margin: 0.05rem;
  }
  .aek-m10 {
    margin: 0.1rem;
  }
  .aek-m15 {
    margin: 0.15rem;
  }
  .aek-m20 {
    margin: 0.2rem;
  }
  .aek-m22 {
    margin: 0.22rem;
  }
  .aek-m25 {
    margin: 0.25rem;
  }
  .aek-m30 {
    margin: 0.3rem;
  }
  .aek-m33 {
    margin: 0.33rem;
  }

  .aek-m-auto {
    margin: 0 auto;
  }

  /* 对齐方式 */
  .aek-tal {
    text-align: left;
  }

  .aek-tac {
    text-align: center;
  }

  .aek-tar {
    text-align: right;
  }

  .aek-vam {
    vertical-align: middle;
  }

  .aek-vab {
    vertical-align: bottom;
  }

  /* 字体大小 */
  .aek-f12 {
    font-size: 0.12rem;
  }

  .aek-f14 {
    font-size: 0.14rem;
  }

  .aek-f16 {
    font-size: 0.16rem;
  }

  .aek-f18 {
    font-size: 0.18rem;
  }

  .aek-f20 {
    font-size: 0.2rem;
  }

  .aek-f22 {
    font-size: 0.22rem;
  }

  .aek-f24 {
    font-size: 0.24rem;
  }

  .aek-f26 {
    font-size: 0.26rem;
  }

  .aek-f28 {
    font-size: 0.28rem;
  }

  .aek-f30 {
    font-size: 0.3rem;
  }

  .aek-f32 {
    font-size: 0.32rem;
  }

  .aek-f33 {
    font-size: 0.33rem;
  }

  .aek-f34 {
    font-size: 0.34rem;
  }

  .aek-f36 {
    font-size: 0.36rem;
  }

  .aek-f38 {
    font-size: 0.38rem;
  }

  .aek-f40 {
    font-size: 0.4rem;
  }

  .aek-f42 {
    font-size: 0.42rem;
  }

  .aek-f44 {
    font-size: 0.44rem;
  }

  .aek-f46 {
    font-size: 0.46rem;
  }

  .aek-f48 {
    font-size: 0.48rem;
  }

  .aek-f50 {
    font-size: 0.5rem;
  }

  /* 字粗 */
  .aek-fw600 {
    font-weight: 600;
  }

  /* 透明度 */
  .aek-opacity10 {
    opacity: 0.1;
  }

  .aek-opacity20 {
    opacity: 0.2;
  }

  .aek-opacity30 {
    opacity: 0.3;
  }

  .aek-opacity40 {
    opacity: 0.4;
  }

  .aek-opacity50 {
    opacity: 0.5;
  }

  .aek-opacity60 {
    opacity: 0.6;
  }

  .aek-opacity70 {
    opacity: 0.7;
  }

  .aek-opacity80 {
    opacity: 0.8;
  }

  .aek-opacity90 {
    opacity: 0.9;
  }

  //颜色
  .common-grey-bg {
    background: #f7f7f7;
  }

  .aek-white-bg {
    background: #ffffff;
  }

  .aek-primary-bg {
    background: $aek-primary;
  }

  // 背景色
  .aek-gray-bg {
    background: $aek-bg-gray;
  }

  .aek-text-primary,
  .ui-selected,
  .ui-selected:hover {
    color: $aek-primary;
  }

  /** 字体颜色 */
  .aek-color-000 {
    color: #000;
  }
  .aek-color-333 {
    color: #333;
  }
  .aek-color-666 {
    color: #666;
  }
  .aek-color-999 {
    color: #999;
  }
  .aek-color-ccc {
    color: #ccc;
  }
  .aek-color-fff {
    color: #fff;
  }
  .aek-color-red {
    color: #ff0000;
  }
  .aek-color-primary {
    color: $aek-primary;
  }

  .aek-text-oceanBlue {
    color: #005dd1;
  }
  .aek-text-white {
    color: #ffffff;
  }
  .aek-text-gray {
    color: $aek-gray;
  }
  .aek-text-secondary-gray {
    color: #999999;
  }
  .aek-text-black {
    color: #000000;
  }
  .aek-text-warning {
    color: #f17b7b;
  }
  .aek-text-price {
    color: #ff0000;
  }
  .aek-text-lightGray {
    color: #e0e0e0;
  }

  // 动画
  .aek-text-primary-transition:hover {
    transition-duration: 1s;
    transition-timing-function: ease-out;
    color: $aek-primary;
    opacity: 0.5;
  }

  .aek-text-del-line {
    text-decoration: line-through;
  }

  .aek-user-select {
    user-select: none;
  }

  // 字体大小及边框
  .bold {
    font-weight: bold;
  }

  .aek-font-bold {
    font-weight: bold;
  }

  .aek-border-gray {
    border: 1px solid #e0e0e0;
  }

  .aek-border-bottom-gray {
    border-bottom: 1px solid #e0e0e0;
  }

  .aek-border-left-gray {
    border-left: 1px solid #e0e0e0;
  }

  .aek-border-right-gray {
    border-right: 1px solid #e0e0e0;
  }

  .aek-border-top-gray {
    border-top: 1px solid #e0e0e0;
  }

  .aek-text-undedrline {
    text-decoration: underline;
  }

  .aek-align-center {
    display: flex;
    align-items: center;
  }
  .aek-color-title {
    color: #0d1924;
  }
}
