//文本颜色
$aek-primary: #274ace;
$aek-primary-bright: #00bae8;
$aek-primary-light: #e6faff;
$aek-primary-light-deep: #d1f6ff;
$aek-primary-deep: #005dd1;
$aek-gray: #666666;

$aek-s-gray: #999999;

$aek-bg-fray: #f7f7f7;

$aek-bg-gray: #f5f5f5;

$border-gray: #e0e0e0;

$font-family: -apple-system, miui, BlinkMacSystemFont, PingFangSC-Light, Noto SansCJK, Microsoft YaHei, \5fae\8f6f\96c5\9ed1, \5b8b\4f53, sans-serif,
  Arial;

$fontSize-14: calc(14vw / 19.2);
$fontSize-18: calc(18vw / 19.2);
$fontSize-24: calc(24vw / 19.2);
$fontSize-48: calc(48vw / 19.2);
$h6: calc(6vh / 10.8);
$h8: calc(8vh / 10.8);
$h10: calc(10vh / 10.8);
$h12: calc(12vh / 10.8);
$h14: calc(14vh / 10.8);
$h16: calc(16vh / 10.8);
$h18: calc(18vh / 10.8);
$h20: calc(20vh / 10.8);
$h34: calc(34vh / 10.8);
$h36: calc(36vh / 10.8);
$h48: calc(48vh / 10.8);
$h112: calc(112vh / 10.8);
$h400: calc(400vh / 10.8);
$h474: calc(474vh / 10.8);
$h948: calc(948vh / 10.8);
$content: calc(100vh - 0.7rem);
$content-2: calc((100vh - 0.7rem) / 2);
$w61: calc(61vw / 19.2);
$w638: calc(638vw / 19.2);
