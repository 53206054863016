@import '../Styles/var.scss';

.header {
  // width: 100vw;
  height: 0.7rem;
  background: #fff;
  color: #666666;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  font-size: 14px;
  border: 1px solid #eeeeee;
  .header-content {
    width: 12rem;
    min-width: 740px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .logo-title {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      img {
        width: 120px;
        height: 35px;
      }
    }
    .header-nav {
      margin-left: 1rem;
      display: flex;
      flex-direction: row;
      height: 100%;
      align-items: center;
      .link {
        font-size: 14px;
        margin-right: 0.26rem;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #666666;
        position: relative;
        left: 0;
        top: 0;
      }
      @media screen and (min-width: 1500px) {
        .link {
          font-size: 16px;
        }
      }
      .link:hover {
        color: #274ace;
      }
      .link-active {
        color: #274ace;
      }
    }
    .contacts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .phone {
        text-align: center;
      }
      .action-jump {
        margin-top: 0.03rem;
        display: flex;
        flex-direction: row;
        width: 2rem;
        min-width: 150px;
        justify-content: space-between;
        .button {
          width: 0.96rem;
          height: 0.28rem;
          min-width: 68px;
          min-height: 20px;
          border-radius: 0.14rem;
          text-align: center;
          font-size: 0.12rem;
          cursor: pointer;
          line-height: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .free {
          background: #274ace;
          color: #fff;
          &:hover {
            background: #365be5;
          }
        }
        .login {
          border: 1px solid #274ace;
          color: #274ace;
          &:hover {
            background: #274ace;
            color: #fff;
          }
        }
      }
    }
  }
  .product-link:hover {
    .arrow {
      border-color: #274ace;
    }
    // .product-nav {
    //   display: block;
    // }
  }
  .product-nav {
    display: block;
    background: #fff;
    border-top: 1px solid #eeeeee;
    position: absolute;
    left: 0;
    top: 0.68rem;
    right: 0;
    // width: 100vw;
    box-shadow: 0px 10px 20px 0px rgba(49, 83, 212, 0.05);
    .product-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 12rem;
      margin: 0 auto;
      padding-bottom: 0.24rem;
      .split {
        width: 1px;
        height: 119px;
        background: #ececec;
        margin: 0 0.24rem;
      }
      img {
        width: 48px;
        height: 52px;
        margin-bottom: 3px;
      }
      .title {
        color: #828282;
        font-size: 0.14rem;
        margin: 0.16rem 0 0.23rem 0;
      }
      .left-nav {
        display: flex;
        justify-content: space-between;
        .nav-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          transition: all 0.3s;
          .nav-title {
            color: #333;
            font-size: 0.14rem;
          }
        }
        .nav-item:hover {
          transform: translateY(-0.04rem);
          transition: all 0.3s;
        }
      }
      .left {
        flex: 1;
      }
      .right {
        flex: 1;
      }
    }
  }
}

.arrow {
  width: 0.08rem;
  height: 0.08rem;
  border: #666 solid;
  border-width: 1px 1px 0 0;
  transform: rotate(135deg);
  margin-bottom: 0.06rem;
  margin-left: 0.04rem;
}
.arrow-active {
  border-color: #274ace;
}
.ui-loginTypes-box {
  .loginTypes-item {
    display: flex;
    width: 100%;
    flex-grow: 1;
    align-content: center;
    align-items: center;
    height: 1rem;
    background: rgba(246, 245, 248, 1);
    border-radius: 0.04rem;
    font-size: 0.16rem;
    font-weight: bold;
    color: rgba(102, 102, 102, 1);
    line-height: 0.3rem;
    cursor: pointer;
    img {
      width: 0.6rem;
      height: 0.6rem;
      margin-left: 0.24rem;
      margin-right: 0.28rem;
      border-radius: 50%;
    }
    &:hover {
      background: rgba(229, 225, 238, 1);
    }
    & + .loginTypes-item {
      margin-top: 0.2rem;
    }
  }
}
