@import '../Styles/var.scss';

.footer {
  //height: 6.47rem;
  background: #0e60cf;
  .animation-con {
    position: relative;
    user-select: none;
    overflow: hidden;
    .footer-img-hover {
      position: absolute;
      width: 100%;
      height: 2.88rem;
      top: 0;
      left: -200%;
      z-index: 999;
      animation: 200s linear 1s infinite running slidein;
      img {
        width: auto;
        height: 100%;
      }
    }
    @keyframes slidein {
      from {
        left: -200%;
      }
      to {
        left: 0;
      }
    }
    img {
      width: 19.2rem;
      height: 2.88rem;
    }
    .middle-con {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9999;
      width: 12rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .slogan {
        color: #fff;
        font-size: 0.36rem;
        margin-bottom: 0.44rem;
      }
      .btn-con {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        .left-btn {
          width: 1.6rem;
          height: 0.48rem;
          line-height: 0.48rem;
          border-radius: 0.24rem;
          background-color: #274ace;
          color: #fff;
          margin-right: 0.24rem;
          cursor: pointer;
          &:hover {
            background: #365be5;
          }
        }
        .right-btn {
          width: 1.6rem;
          height: 0.48rem;
          line-height: 0.48rem;
          border: 1px solid #274ace;
          color: #274ace;
          border-radius: 0.24rem;
          cursor: pointer;
          &:hover {
            background: #274ace;
            color: #fff;
          }
        }
      }
    }
  }
  .product-apply {
    height: 3rem;
    position: relative;
    left: 0;
    top: 0;
    overflow: hidden;
    .apply-bg {
      position: absolute;
      left: 0;
      top: 0;
      img {
        height: 3rem;
      }
    }
    .apply-content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
    }
    .title {
      text-align: center;
      margin-top: 0.9rem;
      margin-bottom: 0.44rem;
      color: #0d1924;
      font-size: 0.36rem;
    }
    .apply-btn {
      text-align: center;
      width: 1.6rem;
      height: 0.48rem;
      line-height: 0.48rem;
      margin: 0 auto 0 auto;
      border-radius: 0.24rem;
      background: #274ace;
      color: #fff;
      font-size: 0.16rem;
      cursor: pointer;
      &:hover {
        background-color: #365be5;
      }
    }
  }
  .footer-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 2.2rem;
    .middle-position {
      width: 12rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .contact-left {
        color: #fff;
        flex: 8;
        .contact-item {
          display: flex;
          flex-wrap: wrap;
          flex: 2;
          & > div {
            // width:50%;
            font-size: 0.14rem;
            padding: 0.18rem 0;
            & > span:first-child {
              width: 0.7rem;
            }
          }
          & > div:nth-child(even) {
            width: 35%;
          }
          & > div:nth-child(odd) {
            width: 65%;
            // margin
          }
        }
      }
      .vertical-line {
        height: 1.06rem;
        border-right: 1px solid #e3e3e3;
        flex: 1;
      }
      .contact-right {
        flex: 4;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 0.3rem;
        .img-wrap {
          .img-con {
            width: 1.33rem;
            height: 1.33rem;
            border: 1px solid #fff;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .img-con-tips {
            text-align: center;
            margin-top: 0.12rem;
            font-size: 14px;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }
  .merit {
    padding: 0.4rem 0.54rem 0.2rem 0;
    border-bottom: 1px solid #e0e0e0;
    .merit-item {
      display: flex;
      align-items: center;
      .default-box {
        margin-right: 0.24rem;
      } //第一个图片盒子不需要border
      .img-box {
        padding-left: 0.15rem;
        margin-right: 0.24rem;
        border-left: 1px solid #e0e0e0;
        .icon {
          width: 0.36rem;
          height: 0.36rem;
        }
      }
      .text {
        display: flex;
        flex-direction: column;
        font-family: MicrosoftYaHei;
        span:nth-child(1) {
          color: #333333;
          font-size: 0.16rem;
          margin-bottom: 0.1rem;
        }
        span:nth-child(2) {
          color: #666666;
          font-size: 0.12rem;
        }
      }
    }
    .item-margin-left {
      padding-left: 0.38rem;
    } //第一个国家监督设置padding-left
  }
  .marin-0-10 {
    margin: 0 0.1rem;
  }
  .links-col {
    display: flex;
    justify-content: space-between;
    padding-right: 1.1rem;
    border-right: 1px solid #e0e0e0;
  }

  .column {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .column-label {
    margin-bottom: 0.15rem;
    color: #333333;
    font-size: 0.14rem;
    width: 100%;
    font-family: MicrosoftYaHei;
  }

  .column-link {
    color: #666666;
    font-size: 0.12rem;
    & + .column-link {
      margin-left: 0.2rem;
    }
  }

  .column-link:hover {
    color: $aek-primary;
  }

  .hotline {
    display: flex;
    align-items: center;
    flex-direction: column;
    div:nth-child(1) {
      margin-bottom: 0.15rem;
      color: #333333;
      font-size: 0.12rem;
    } //全国服务热线
    div:nth-child(2) {
      margin-bottom: 0.1rem;
      color: $aek-primary;
      font-size: 0.22rem;
    } //400...
    div:nth-child(3) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.1rem;
      height: 28px;
      border: 1px solid $aek-primary;
      border-radius: 0.02rem;
      color: $aek-primary;
      font-size: 0.12rem;
      & > i {
        margin-right: 0.1rem;
        width: 0.14rem;
        height: 0.12rem;
      }
      & > i::before {
        content: '';
        width: 0.14rem;
        height: 0.12rem;
        display: inline-block;
        //background-image: url(../Assets/footer-icon5.png);
        background-size: 0.14rem 0.12rem;
      }
    }
  }

  .copyright-box {
    min-width: 900px;
    background: #333333;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #094ba4;
    a {
      color: #fff;
    }
  }

  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 12px;
    margin-top: 3px;
  }
}
