@import 'var';

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $aek-primary;
  border-color: $aek-primary;
}

.ant-tabs-tab.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    color: $aek-primary;
  }
}

.ant-tabs-ink-bar {
  background-color: $aek-primary;
}
